<template>
  <div class="contents userInfo review">
    <div class="title flexB">
      <h2>제휴문의 관리</h2>
    </div>

    <div class="box one full">
      <div class="flex">
        <p class="bold">등록일</p>
        <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
      </div>
      <div class="flex">
        <p class="bold">제목</p>
        <span>{{ title }}</span>
      </div>
      <p></p>
      <div class="flex">
        <p class="bold">내용</p>
        <pre>{{ content }}</pre>
      </div>
      <div class="flex" v-if="images">
        <p class="bold">제안서</p>
        <div class="fileWrap">
          <input disabled type="text" v-model="images.filename" class="upload" />
          <a :href="images.url">
            <button class="point">다운로드</button>
          </a>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/partnershipManage">목록</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchQnADetail } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  name: "partnershipDetail",
  mixins: [format],
  data() {
    return {
      moment: moment,
      title: "",
      content: "",
      createdAt: "",
      isReply: false,
      images: [],
      qnaId: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "12");
  },
  mounted() {
    this.getQna();
  },
  methods: {
    getQna() {
      this.qnaId = this.$route.query.id;

      fetchQnADetail(this.qnaId).then((res) => {
        if (res.data.status == 200) {
          this.content = res.data.data.content;
          this.isReply = res.data.data.isReply;
          this.createdAt = res.data.data.createdAt;
          this.title = res.data.data.title;
          this.images = res.data.data.images[0];
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
